<template>
  <div
    v-if="!underConstruction"
    id="app-header"
    class="h-15vh 2xl:h-10vh w-screen fixed z-50"
  >
    <Header />
  </div>
  <div
    id="app-body"
    class="overflow-hidden"
    :class="{
      'h-full pt-0': underConstruction,
      'min-h-80vh app-top-padding': !underConstruction,
    }"
  >
    <router-view />
  </div>
  <div v-if="!underConstruction" id="app-footer">
    <Footer />
  </div>
</template>

<script src="./default.js"></script>
<style src="./default.css" scoped lang="css"></style>
